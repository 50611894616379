export enum ChoiceEnum {
    PAPIER = 'PAPIER',
    CAILLOUX = 'CAILLOUX',
    CISEAUX = 'CISEAUX',
    PUIT = 'PUIT',
    PIED = 'PIED'
}

export enum IndexChoiceEnum {
    PAPIER,
    CAILLOUX,
    CISEAUX,
    PUIT,
    PIED
}