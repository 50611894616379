import React, {useEffect, useState} from 'react';
import {LinkComponent} from '../../components/link/link.component';
import {ReactComponent as OnlineImg} from '../../assets/images/mode/online.svg';
import {ReactComponent as OfflineImg} from '../../assets/images/mode/offline.svg';
import {Navigate} from 'react-router-dom';
import styles from './mode.module.scss';
import {useTranslation} from 'react-i18next';

export const ModePage = () => {
    const {t} = useTranslation();
    const [onlineLink, setOnlineLink] = useState<string>('');
    const [hover, setHover] = useState<'online' | 'offline' | null>(null);
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

    useEffect(() => {
        setOnlineLink(uniqueId());
    }, []);

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substring(2, 5);
        return dateString + randomness;
    };

    if (!!redirectUrl) {
        return <Navigate to={redirectUrl}/>
    }
    return (
        <div className={styles.mode}>
            <div className={styles.mode__container}>
                <h1 className={`${styles.mode__title} ${!!hover && styles.mode__title_hover}`}>{t('modePage.title')}</h1>
                <div className={`${styles.mode__choices} container-fluid`}>
                    <div className='row'>
                        <div className={`col-12 col-md-5 offset-lg-1 ${hover === 'offline' && 'col-lg-5'} ${hover === 'online' ? 'col-lg-3' : ' col-lg-4'} ${styles.mode__choices__item}`}>
                            <div
                                className={styles.item}
                                onClick={() => setRedirectUrl('/game')}
                                onMouseEnter={(() => setHover('offline'))}
                                onMouseLeave={(() => setHover(null))}>
                                <OfflineImg
                                    className={`${styles.item__img} ${hover === 'offline' && styles.active} ${hover === 'online' && styles.inactive}`}/>
                                <div className={styles.item__link}>
                                    <LinkComponent text={t('modePage.computer')}
                                                   arrow/>
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-2 ${styles.mode__choices__or} ${!!hover ? styles.mode__choices__or_disabled : ''}`}>{t('modePage.or')}</div>
                        <div className={`col-12 col-md-5 col-lg-4 ${hover === 'online' && 'col-lg-5'} ${hover === 'offline' ? 'col-lg-3' : ' col-lg-4'} ${styles.mode__choices__item}`}>
                            <div
                                onClick={() => setRedirectUrl(`/${onlineLink}`)}
                                className={styles.item}
                                onMouseEnter={(() => setHover('online'))}
                                onMouseLeave={(() => setHover(null))}>
                                <OnlineImg
                                    className={`${styles.item__img} ${hover === 'online' && styles.active} ${hover === 'offline' && styles.inactive}`}/>
                                <div className={styles.item__link}>
                                    <LinkComponent text={t('modePage.challenger')}
                                                   arrow/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}