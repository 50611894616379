import {ReactComponent} from '*.svg';

export type ExtraType = {
    id: ExtraIdEnum
    img: typeof ReactComponent,
    name: string
    text: string
    opponentText: string
    type: ExtraEnum
}

export enum ExtraEnum {
    BONUS = 'bonus',
    MALUS = 'malus'
}

export enum ExtraIdEnum {
    PUIT = 'puit',
    AMBIDEXTRIE = 'ambidextrie',
    PIED = 'pied',
    BAPTISTE = 'baptiste'
}