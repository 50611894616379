import {CodePage} from '../../components/online/code/code.page';
import {Navigate, useOutletContext, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {
    cleanSocket,
    disconnectSocket,
    onConnectSocket,
    onMessageSocket,
    onResetSocket
} from '../../services/socket/socket.service';
import {StatusGameType} from '../../types/socket.type';
import {OnlineGamePage} from '../../components/online/game/game.page';
import {WaitingPage} from '../../components/online/waiting/waiting.page';
import styles from './online.module.scss';
import {getAppUrl, getMobileOs} from '../../services/deeplink/deeplink.service';
import {ContextType} from '../../types/context.type';
import {UserAgentEnum} from '../../enumerator/deeplink.enum';

export const OnlinePage = () => {
    const {room} = useParams();
    const {isMobileApp} = useOutletContext<ContextType>();
    const [step, setStep] = useState<'code' | 'waiting' | 'game'>('code');
    const [keyGame, setKeyGame] = useState<number>(Date.now());
    const [gameOwner, setGameOwner] = useState<boolean>(false);
    const [socketId, setSocketId] = useState<string>();
    const [redirect, setRedirect] = useState<string | null>(null);

    const [appUrl, setAppUrl] = useState<string | null>(null);

    useEffect(() => {
        if (!!room) {
            const mobileOs = getMobileOs();
            if (!!mobileOs) {
                const appUrl = getAppUrl(room, mobileOs);
                mobileOs === UserAgentEnum.ANDROID ?
                    openAndroid(room, appUrl) : openIOS(room, appUrl);
            } else {
                initSocket(room)
            }
        }
        return () => {
            disconnectSocket();
            cleanSocket();
        };
    }, [room]);

    const openAndroid = (room: string, url: string) => {
        setAppUrl(url);
        setTimeout(() => {
            if (document.visibilityState !== 'hidden') {
                setAppUrl(null);
                initSocket(room);
            }
        }, 1500);
    }


    const openIOS = (room: string, url: string) => {
        window.location.href = url;
        setTimeout(() => {
            if (document.visibilityState !== 'hidden') {
                initSocket(room);
            }
        }, 3000);
    }

    /**
     * On init la room avec le websocket
     * @param room
     */
    const initSocket = (room: string) => {
        onConnectSocket(room, (id) => setSocketId(id))
        onMessageSocket(roomStatus);
        onResetSocket(resetRoom);
    }

    /**
     * On reçoit les events de statut de la salle
     * @param status
     */
    const roomStatus = (status: StatusGameType) => {
        if (status === 'ready' && step !== 'game') {
            setStep('waiting');
        } else if (status === 'empty') {
            setGameOwner(true);
            setStep('code');
        } else if (status === 'complete') {
            setRedirect('complete')
        }
    }

    /**
     * Quand l'adversaire s'est déconnecté.
     */
    const resetRoom = () => {
        setKeyGame(Date.now());
        setRedirect('disconnect');
    }

    /**
     * On lance la game
     */
    const launchGame = () => {
        if (step === 'waiting') {
            setKeyGame(Date.now());
            setStep('game');
        }
    }

    /**
     * On quitte le mode multijoueur
     */
    const backToMode = () => {
        setRedirect('mode');
    }

    if (!!redirect) {
        return <Navigate to={`/${redirect}`}/>
    }

    return !room ? <></> : (
        <div className={styles.online}>
            {!!appUrl && (
                <iframe style={{display: 'none'}}
                        height='0'
                        width='0'
                        src={appUrl}></iframe>
            )}
            {isMobileApp && (
                <div className={styles.online__back}
                     onClick={() => backToMode()}>
                    <span className="material-symbols-outlined">arrow_back</span>
                </div>
            )}
            {step === 'code' && (
                <CodePage room={room}
                          isMobileApp={isMobileApp}/>
            )}
            {step === 'waiting' && (
                <WaitingPage callbackTimer={() => launchGame()}
                             gameOwner={gameOwner}/>
            )}
            {step === 'game' && (
                <OnlineGamePage room={room}
                                socketId={socketId || ''}
                                key={keyGame}/>
            )}
        </div>
    )
}
