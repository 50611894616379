import {ReactComponent as Baptiste} from '../assets/images/bonus/icons/pasnet.svg';
import {ReactComponent as Pied} from '../assets/images/bonus/icons/feet.svg';
import {ExtraEnum, ExtraIdEnum, ExtraType} from '../types/extra.type';

export const malusData: ExtraType[] = [
    {
        id: ExtraIdEnum.PIED,
        img: Pied,
        name: 'malus.pied.title',
        text: 'malus.pied.text',
        opponentText: 'malus.pied.opponentText',
        type: ExtraEnum.MALUS
    }, {
        id: ExtraIdEnum.BAPTISTE,
        img: Baptiste,
        name: 'malus.baptiste.title',
        text: 'malus.baptiste.text',
        opponentText: 'malus.baptiste.opponentText',
        type: ExtraEnum.MALUS
    }
]