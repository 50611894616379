import styles from './extraChoice.module.scss';
import {ReactComponent as MalusImg} from '../../../../assets/images/online/extra/malus.svg';
import {ReactComponent as BonusImg} from '../../../../assets/images/online/extra/bonus.svg';
import {LinkComponent} from '../../../link/link.component';
import React, {useState} from 'react';
import {ExtraEnum} from '../../../../types/extra.type';
import {useTranslation} from 'react-i18next';

export const ExtraChoiceComponent = ({callback}: {callback: (extra: ExtraEnum) => void}) => {
    const {t} = useTranslation();
    const [hover, setHover] = useState<ExtraEnum | null>(null);

    return (
        <div className={styles.extra}>
            <h1 className={`${styles.extra__title} ${!!hover && styles.extra__title_hover}`}>{t('extraPage.title1')} <br/> {t('extraPage.title2')}</h1>
            <div className={`${styles.extra__choices} container-fluid`}>
                <div className='row'>
                    <div className={`col-12 col-md-5 offset-lg-1 ${hover === ExtraEnum.BONUS && 'col-lg-5'} ${hover === ExtraEnum.MALUS ? 'col-lg-3' : ' col-lg-4'} ${styles.extra__choices__item}`}>
                        <div
                            className={styles.item}
                            onClick={() => callback(ExtraEnum.BONUS)}
                            onMouseEnter={(() => setHover(ExtraEnum.BONUS))}
                            onMouseLeave={(() => setHover(null))}>
                            <BonusImg
                                className={`${styles.item__img} ${hover === ExtraEnum.BONUS && styles.active} ${hover === ExtraEnum.MALUS && styles.inactive}`}/>
                            <div className={styles.item__link}>
                                <LinkComponent text='Je prend le bonus'
                                               arrow/>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-md-2 ${styles.extra__choices__or} ${!!hover ? styles.extra__choices__or_disabled : ''}`}>ou alors</div>
                    <div className={`col-12 col-md-5 col-lg-4 ${hover === ExtraEnum.MALUS && 'col-lg-5'} ${hover === ExtraEnum.BONUS ? 'col-lg-3' : ' col-lg-4'} ${styles.extra__choices__item}`}>
                        <div
                            onClick={() => callback(ExtraEnum.MALUS)}
                            className={styles.item}
                            onMouseEnter={(() => setHover(ExtraEnum.MALUS))}
                            onMouseLeave={(() => setHover(null))}>
                            <MalusImg
                                className={`${styles.item__img} ${styles.item__img_malus}  ${hover === ExtraEnum.MALUS && styles.active} ${hover === ExtraEnum.BONUS && styles.inactive}`}/>
                            <div className={styles.item__link}>
                                <LinkComponent text='Il prend le malus'
                                               color='var(--theme-background-dark)'
                                               arrow/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}