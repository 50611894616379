import {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {App} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';

export const LayoutComponent = () => {
    const navigate = useNavigate();
    const [isMobileApp] = useState<boolean>(Capacitor.getPlatform() !== 'web');

    useEffect(() => {
        App.addListener('appUrlOpen', data => {
            try {
                const query = data.url.split('?')[1];
                const urlParams = new URLSearchParams(`?${query}`);
                const room = urlParams.get('room');
                if (!!room) {
                    navigate(`/${room}`);
                }
            } catch (e) {
                console.log(e);
            }
        });
    }, []);
    return (
        <>
            <Outlet context={{isMobileApp}} />
        </>
    )
}