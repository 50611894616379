import React from "react";
import {ReactComponent as LinkIcon} from "../../assets/images/link.svg";

import styles from "./link.module.scss";

export const LinkComponent = ({
    text,
    arrow,
    color = 'var(--theme-link)',
    icon,
    inheritSize,
    callback
}: {
    text: string,
    color?: string,
    icon?: string,
    arrow?: boolean,
    inheritSize?: boolean,
    callback?: () => void
}) => {
    const colorLink = {
        color,
        fill: color,
        stroke: color,
    };

    const onClick = () => {
        if (!!callback) {
            callback();
        }
    }

    return (
        <p className={`${styles.link} ${inheritSize && styles.link_noSize}`}
           onClick={() => onClick()}
           style={colorLink}>
            {!!icon && (
                <span className={`material-symbols-outlined ${styles.link__icon}`}>{icon}</span>
            )}
            {text}
            {arrow && (
                <span className={`material-symbols-outlined ${styles.link__arrow}`}>arrow_forward</span>
            )}
            <span className={styles.link__graphic}>
                <LinkIcon/>
            </span>
        </p>
    );
};
