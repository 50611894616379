import React, {useState} from 'react';
import {ReactComponent as OctogoneImg} from '../../assets/images/game/octogone.svg';
import {ChoicesComponent} from '../../components/choices/choices.component';
import styles from './game.module.scss';
import {ChoiceEnum} from '../../enumerator/choice.enum';
import {getPicture, getScore, makeRandomChoice} from '../../services/game/game.service';
import {ResultComponent} from '../../components/result/result.component';
import {HeaderComponent} from '../../components/header/header.component';
import {CounterComponent} from '../../components/counter/counter.component';
import {ExtraIdEnum, ExtraType} from '../../types/extra.type';
import {ExtraComponent} from '../../components/extra/extra.component';
import {checkResultByHistory} from '../../services/extra/extra.service';
import {Navigate, useOutletContext} from 'react-router-dom';
import {ContextType} from '../../types/context.type';

export const GamePage = () => {
    const {isMobileApp} = useOutletContext<ContextType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string | null>(null);

    const [bonus, setBonus] = useState<boolean>(false);
    const [malus, setMalus] = useState<boolean>(false);
    const [extraItem, setExtraItem] = useState<ExtraType | null>();

    const [round, setRound] = useState<number>(1);

    const [scorePlayer1, setScorePlayer1] = useState<number>(0);
    const [scorePlayer2, setScorePlayer2] = useState<number>(0);

    const [choiceUser, setChoiceUser] = useState<ChoiceEnum[]>([]);
    const [choiceIA, setChoiceIA] = useState<ChoiceEnum[]>([]);

    const [result, setResult] = useState<number | undefined>(undefined);
    const [resultHistory, setResultHistory] = useState<number[]>([]);

    /**
     * On affiche les mains après le timer
     */
    const displayHands = () => {
        setLoading(false);
        if (choiceUser?.length && choiceIA?.length) {
            setTimeout(() => {
                const score = getScore({
                    player1: choiceUser,
                    player2: choiceIA
                });
                if (score === 1) {
                    setScorePlayer1(scorePlayer1 + 1);
                } else if (score === 2) {
                    setScorePlayer2(scorePlayer2 + 1);
                }
                setResult(score);
                setExtraItem(null);
                setResultHistory([
                    ...resultHistory,
                    score
                ])
            }, 2500);
        }
    }

    /**
     * On enregistre le choix + on lance le choix de l'IA
     * @param choice: ChoiceEnum
     */
    const onChoice = (choice: ChoiceEnum) => {
        const newChoiceUser = [...choiceUser, choice]
        setChoiceUser(newChoiceUser);
        if (extraItem?.id === ExtraIdEnum.AMBIDEXTRIE && newChoiceUser.length < 2) {
            return;
        }
        const iaChoice = makeRandomChoice();
        setChoiceIA([...choiceIA, iaChoice]);
        setLoading(true);
    }

    /**
     * On lance la vérif sur qui sont les deux derniers gagnants (si p1 = bonus / si p2 = malus)
     */
    const checkExtra = () => {
        setBonus(checkResultByHistory(1, resultHistory));
        setMalus(checkResultByHistory(2, resultHistory));
    }

    /**
     * On ajoute un extra (bonus ou malus)
     * @param item: ExtraType
     */
    const addExtra = (item: ExtraType) => {
        setBonus(false);
        setMalus(false);
        setExtraItem(item);
    }

    /**
     * On reset les infos en fin de round
     */
    const resetGame = () => {
        checkExtra();
        setRound(round + 1);
        setChoiceUser([]);
        setChoiceIA([]);
        setResult(undefined);
    }

    /**
     * On quitte le mode multijoueur
     */
    const backToMode = () => {
        setRedirect('mode');
    }

    let ChoiceIa;
    if (choiceIA?.length) {
        ChoiceIa = getPicture(choiceIA[0], 'opponent');
    }
    let ChoiceUser;
    let ChoiceUserBonus;
    if (choiceUser?.length) {
        ChoiceUser = getPicture(choiceUser[0], 'player');
        if (choiceUser?.length === 2) {
            ChoiceUserBonus = getPicture(choiceUser[1], 'player');
        }
    }

    // Si aucun résultat + pas chargement + les 2 choix
    const handsDisplay = result === undefined && !loading && !!ChoiceUser && !!ChoiceIa;
    // Si le résultat + les 2 choix
    const resultDisplay = result !== undefined && choiceUser?.length && choiceIA?.length
    // Si aucun résultat
    const choiceDisplay = result === undefined


    if (!!redirect) {
        return <Navigate to={`/${redirect}`}/>
    }
    return (
        <div className={`${styles.game} ${(handsDisplay) && styles.game_appear}`}>
            {isMobileApp && (
                <div className={styles.game__back}
                     onClick={() => backToMode()}>
                    <span className="material-symbols-outlined">arrow_back</span>
                </div>
            )}
            <HeaderComponent round={round}
                             player1={scorePlayer1}
                             player2={scorePlayer2}/>
            {handsDisplay && (
                <div className={styles.game__content}>
                    <div className={styles.choice}>
                        {ChoiceUser && (
                            <div className={`${styles.choice__hand} ${!!ChoiceUserBonus && styles.choice__hand_many}`}>
                                <ChoiceUser className={styles.choice__hand__img}/>
                                {ChoiceUserBonus && <ChoiceUserBonus className={styles.choice__hand__img}/>}
                            </div>
                        )}
                        {ChoiceIa && (
                            <div className={styles.choice__hand}>
                                <ChoiceIa className={styles.choice__hand__img}/>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {loading && <CounterComponent callback={displayHands}/>}

            {resultDisplay && (
                <ResultComponent result={result as number}
                                 resetResult={() => resetGame()}
                                 choices={{
                                     player1: choiceUser,
                                     player2: choiceIA
                                 }}/>
            )}

            {choiceDisplay && (
                <div className={styles.game__footer}>
                    <div className='custom-container'>
                        <div className='row align-items-center'>
                            <div className='col-12 col-lg-6 offset-lg-3'>
                                <ChoicesComponent callback={(choice: ChoiceEnum) => onChoice(choice)}
                                                  extra={extraItem?.id}
                                                  choices={choiceUser}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {(bonus || malus) && (
                <ExtraComponent callback={(item: ExtraType) => addExtra(item)}
                                isBonus={bonus}/>
            )}

            <OctogoneImg
                className={`${styles.game__background} ${extraItem?.id === 'baptiste' ? styles.game__background_blured : ''}`}/>
        </div>
    )
}