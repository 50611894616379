import i18n from "i18next";

import detector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import translationFR from '../../assets/translation/fr.json';
import translationEN from '../../assets/translation/en.json';

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources: {
            fr: {
                translation: translationFR
            },
            en: {
                translation: translationEN
            }
        },
        fallbackLng: "fr",
        interpolation: {
            escapeValue: false
        }
    });