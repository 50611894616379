import React, {useEffect, useState} from 'react';
import {LinkComponent} from '../../components/link/link.component';
import {Navigate} from 'react-router-dom';
import {ReactComponent as CompleteImg} from '../../assets/images/online/complete.svg';
import styles from './complete.module.scss';
import {disconnectSocket} from '../../services/socket/socket.service';
import {useTranslation} from 'react-i18next';

export const CompletePage = () => {
    const {t} = useTranslation();
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

    useEffect(() => {
        disconnectSocket();
    }, [])

    if (!!redirectUrl) {
        return <Navigate to={redirectUrl}/>
    }
    return (
        <div className={styles.complete}>
            <div className={`custom-container ${styles.complete__content}`}>
                <div className='row'>
                    <div className='col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
                        <h1 className={styles.complete__title}>
                            {t('completePage.text')}
                        </h1>
                    </div>
                </div>
            </div>
            <div className={styles.complete__link}>
                <a href='https://www.kleenex.fr/produits/mouchoirs/kleenex-the-original-mouchoirs-boite-xl' target='_blank'>
                    <LinkComponent text={t('completePage.link')}
                                   callback={() => setRedirectUrl('/mode')}
                                   arrow/>
                </a>
            </div>
            <CompleteImg className={styles.complete__img}/>
        </div>
    );
}