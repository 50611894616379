import React, {useEffect, useState} from 'react';
import {LinkComponent} from '../../components/link/link.component';
import {Navigate} from 'react-router-dom';
import {ReactComponent as CompleteImg} from '../../assets/images/online/complete.svg';
import styles from './redirect.module.scss';
import {disconnectSocket} from '../../services/socket/socket.service';
import {useTranslation} from 'react-i18next';

export const RedirectPage = () => {
    const {t} = useTranslation();
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

    useEffect(() => {
        disconnectSocket();
    }, []);

    if (!!redirectUrl) {
        return <Navigate to={redirectUrl}/>
    }
    return (
        <div className={styles.redirect}>
            <div className={`custom-container ${styles.redirect__content}`}>
                <div className='row'>
                    <div className='col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
                        <h1>
                            {t('redirectPage.text')}
                        </h1>
                    </div>
                </div>
            </div>
            <div className={styles.redirect__link}>
                <LinkComponent text={t('redirectPage.link')}
                               callback={() => setRedirectUrl('/mode')}
                               arrow/>
            </div>
            <CompleteImg className={styles.complete__img}/>
        </div>
    );
}