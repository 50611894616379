import styles from './rgpd.module.scss';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const RgpdPage = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.rgpd}>
            <div className='custom-container'>
                <div className=''>
                    <div className='col-12 col-lg-8 offset-lg-2'>
                        <h1>Politique de Protection et Gestion des Données Personnelles</h1>
                        <h2>Préambule</h2>
                        <p>Tizy procède au traitement de Données Personnelles (telles que définies ci-après) dans le
                            cadre
                            de son activité, ce qui inclut les Données Personnelles des personnes qui naviguent sur le
                            site Web (le « Site ») pcc.tizy.fr.
                        </p>
                        <p>La présente « Politique de Protection des Données Personnelles » (la « Politique ») a pour
                            objet d’informer toutes les personnes physiques concernées (« Vous » ou « Votre/Vos ») sur
                            la manière dont Tizy collecte et utilise de telles Données Personnelles et sur les moyens
                            dont Vous disposez pour contrôler cette utilisation.
                        </p>
                        <h2>Article 1 - Champ d’application de la présente politique de protection des données
                            personnelles
                        </h2>
                        <p>La présente Politique énonce les principes et lignes directrices pour la protection de Vos
                            Données Personnelles, ce qui inclut les Données Personnelles collectées sur - ou via - le
                            Site.
                        </p>
                        <p>Tizy collecte des Données Personnelles en ligne (y compris par e-mail) ou hors connexion ; la
                            présente Politique s’applique quel que soit le mode de collecte ou de traitement.
                        </p>
                        
                        <p>La notion de données personnelles (les « Données Personnelles ») désigne toute information
                            relative à une personne physique identifiée ou identifiable. Une personne est « identifiable
                            » dès lors qu’elle peut être identifiée, directement ou indirectement, en particulier par
                            référence à un numéro d’identification ou à un ou plusieurs éléments qui lui sont propres.
                        </p>
                        <p>Les « Données Non-Personnelles » correspondent à des informations ne permettant pas
                            d’identifier une personne.
                        </p>
                        <h2>Article 2 - Lieux de stockage des Données Personnelles
                        </h2>
                        <p>L’ensemble des Données Personnelles est stocké exclusivement sur des serveurs en France, par
                            notre prestataire d’hébergement web dont le siège social est :
                        </p>
                        
                        <p style={{textAlign: 'center'}}>OVH Groupe SA<br/>2, rue Kellermann<br/>59100 Roubaix<br/>FRANCE</p>
                        <h2>Article 3 - Responsable du traitement
                        </h2>
                        <p>Le responsable du traitement de Vos Données Personnelles est Tizy ; certaines prestations
                            techniques (l’hébergement, le traitement et le stockage des données) sont confiées à nos
                            prestataires techniques : OVH (FRANCE) et Brevo (FRANCE). Conformément à l’Article 28
                            du RGPD, ces prestataires sont liés par une clause de stricte confidentialité qui leur
                            interdit toute utilisation des données qui leur sont confiées non-prévue dans le contrat de
                            prestation et qui leur impose de mettre en œuvre les mesures techniques et
                            organisationnelles qui satisfont aux exigences de sécurité et de protection des données
                            personnelles.
                        </p>
                        <h2>Article 4 - Données collectées</h2>
                        <h3>Données Personnelles</h3>
                        <p>Les Données Personnelles peuvent inclure ce qui suit :
                        </p>
                        <ul>
                            <li>Adresse IP</li>
                            <li>Informations de matériel (UUID ou Device ID)
                            </li>
                        </ul>
                        <p>En tout état de cause, les Données Personnelles collectées se limiteront aux données
                            nécessaires aux fins exposées ci-après.
                        </p>
                        
                        <p>Note à l’attention des visiteurs et utilisateurs du Site : certaines fonctionnalités et
                            caractéristiques du Site ne peuvent être utilisées que si Vous communiquez à Tizy certaines
                            Données Personnelles lorsque Vous visitez ou utilisez le Site. Vous êtes libre de fournir ou
                            non tout ou partie de Vos Données Personnelles.
                        </p>
                        
                        <p>Toutefois, si Vous choisissez de ne pas les fournir, une telle décision pourrait empêcher la
                            réalisation ou la réalisation satisfaisante des objectifs décrits ci-après, certains
                            services et certaines fonctionnalités du Site pourraient ne pas fonctionner correctement
                            et/ou Vous vous verrez refuser l’accès à certaines pages du Site.
                        </p>
                        <h3>Cookies et autres traceurs
                        </h3>
                        <p>Le Site peut utiliser des cookies ou d’autres technologies susceptibles de collecter ou
                            conserver des Données Personnelles. Les cookies sont des fichiers textes stockés et utilisés
                            pour enregistrer des Données Personnelles et Non Personnelles concernant Votre navigation
                            sur le Site.
                        </p>
                        
                        <p>Les cookies peuvent être permanents (et rester après Votre déconnexion du Site afin d’être
                            utilisés lors de Vos visites ultérieures sur le Site) ou temporaires (et disparaître dès que
                            Vous vous déconnectez du Site).
                        </p>
                        
                        <p>Tizy utilise des cookies pour améliorer votre expérience d’utilisateur, notamment en :
                        </p>
                        <ul>
                            <li>permettant à un service de reconnaître Votre matériel, ainsi Vous n’aurez pas à fournir
                                plusieurs fois les mêmes informations afin d’exécuter la même tâche.
                            </li>
                        </ul>
                        <p>Tizy utilise des cookies pour analyser le trafic et les données sur le Site afin de :
                    </p>
                        <ul>
                            <li>mesurer le nombre d’utilisateurs des services, permettant ainsi de les rendre plus
                                faciles à utiliser et de s’assurer de leur capacité à répondre de manière rapide à Vos
                                requêtes ;
                            </li>
                            <li>aider Tizy à comprendre la manière dont les utilisateurs interagissent avec les services
                                afin de les améliorer.
                            </li>
                        </ul>
                        <p>Tizy peut également recourir aux services de prestataires tiers aux fins d’exécuter
                        les services pour son compte et notamment pour :
                    </p>
                        <ul>
                            <li>analyser Vos habitudes de navigation et mesurer l’audience du Site ;
                            </li>
                            <li>vous permettre de partager le contenu du Site avec d’autres personnes sur les réseaux
                                sociaux ou de faire savoir à ces autres personnes ce que Vous regardez ou ce que Vous
                                pensez.
                            </li>
                        </ul>
                        
                        <p>Dans ce cas, des "pixels" invisibles et des cookies fournis par ces prestataires tiers
                            pourraient être utilisés et conservés. Lors de la transmission des informations générées par
                            ces cookies, les paramètres des cookies veillent à ce que l’adresse IP soit rendue anonyme
                            avant la conservation. Nos prestataires utiliseront ces informations aux fins d’évaluer
                            Votre utilisation du Site, de rédiger des rapports sur l’activité du Site pour Tizy, et de
                            Vous proposer de meilleurs services sur Vos besoins. Ils n’associeront votre adresse IP à
                            aucune autre de leurs données.
                        </p>
                        
                        <p>Lorsque vous naviguez sur le Site, les cookies facultatifs sont désactivés par défaut et les
                            données peuvent être lues ou conservées localement sur votre matériel. Vous serez averti la
                            première fois que Vous recevez un cookie et Vous pourrez ainsi décider de l’accepter ou de
                            le refuser.
                        </p>
                        
                        <p>Vous pouvez également configurer Votre navigateur de manière à refuser systématiquement les
                            cookies (y compris les cookies utilisés à des fins de mesure d’audience). Toutefois, si tel
                            était le cas, certaines fonctionnalités et caractéristiques du Site pourraient ne pas
                            fonctionner correctement et Vous pourriez ne pas être en mesure de pouvoir accéder à
                            certain(e)s parties ou services du Site.
                        </p>
                        
                        <p>Si vous souhaitez modifier ou supprimer vos informations, veuillez vous référer à l'Article 8
                            : Vos droits.
                        </p>
                        <h3>Conservation des données</h3>
                        <p>Par type de données :</p>
                        <ul>
                            <li>Données de navigation (adresse IP) ou d’identification : 3 ans max.
                            </li>
                        </ul>
                        
                        <p>Nous ne conservons Vos Données Personnelles que pour une durée nécessaire et proportionnée à
                            la finalité pour laquelle elles ont été collectées à savoir pour une durée maximale de 3 ans
                            à compter du dernier échange que vous avez eu avec nous et effectué à votre initiative.
                        </p>
                        
                        <p>Au-delà des périodes précitées, vos données seront purement supprimées ou anonymisées.
                        </p>
                        <h2>Article 5 - Finalités de la collecte des données personnelles
                        </h2>
                        <p>Comme indiqué ci-dessus, Vous fournissez Vos Données Personnelles de manière volontaire.
                        </p>
                        
                        <p>Les Données Personnelles sont généralement collectées pour les besoins de l’activité de Tizy
                            tels que l’amélioration des efforts marketing et publicitaires de Tizy, une meilleure
                            adaptation des produits et services de Tizy aux besoins des clients ou le respect des
                            obligations déclaratives prévues par la loi, et d’autres activités similaires.
                        </p>
                        
                        <p>Tizy collecte et utilise les Données Personnelles Vous concernant pour les besoins de son
                            activité et notamment aux fins suivantes :
                        </p>
                        <ul>
                            <li>vous permettre de demander et d’obtenir des informations sur Tizy et les produits et
                                services de Tizy ;
                            </li>
                            <li>vous permettre une utilisation interactive et personnalisée du Site ;
                            </li>
                            <li>cerner Vos besoins et centres d’intérêt et pour Vous fournir les produits et/services
                                les mieux adaptés ;
                            </li>
                            <li>permettre à Tizy de gérer des enquêtes ;</li>
                            <li>permettre à Tizy de gérer des activités marketing ;
                            </li>
                            <li>permettre à Tizy de gérer ses relations commerciales : opportunités, commerciales,
                                offres commerciales, achats, contrats, commandes, factures, etc ;
                            </li>
                            <li>vous proposer des services de type commercial ou de support ;
                            </li>
                            <li>vous permettre d’acheter des produits ou services ;
                            </li>
                            <li>vous permettre de Vous inscrire à des séminaires, webinaires ou évènements ;
                            </li>
                            <li>permettre à Tizy de gérer ses activités de Recherche et Développement : demandes de
                                développement, incidents, les questions de qualité, tests, etc. ;
                            </li>
                        </ul>
                        
                        <p>Sous réserve de la législation locale applicable, en fournissant Votre adresse de courrier
                            électronique, Vous autorisez expressément Tizy à l’utiliser avec d’autres Données
                            Personnelles utiles pour Vous envoyer des messages commerciaux ou de marketing.
                        </p>
                        <p>Tizy est également susceptible d’utiliser Votre adresse de courrier électronique à des fins
                            administratives ou d’autres objectifs ne relevant pas du marketing (par exemple, pour Vous
                            notifier d’importants changements apportés au Site).
                        </p>
                        <h2>Article 6 - Conditions de traitement et de stockage des
                            données personnelles
                        </h2>
                        <p>Le « traitement » des Données Personnelles inclut notamment l’utilisation, la conservation,
                            l’enregistrement, le transfert, l’adaptation, l’analyse, la modification, la déclaration, le
                            partage et la destruction des Données Personnelles en fonction de ce qui est nécessaire au
                            regard des circonstances ou des exigences légales.
                        </p>
                        
                        <p>Toutes les Données Personnelles collectées sont conservées pour une durée limitée en fonction
                            de la finalité du traitement et uniquement pour la durée prévue par la législation
                            applicable.
                        </p>
                        
                        <p>Toutes les Données Personnelles collectées sont stockées en France Métropolitaine auprès d’un
                            hébergeur habilité à stocker des Données Personnelles.
                        </p>
                        <h2>Article 7 - Liens vers des sites web non contrôlés par Tizy</h2>
                        <p>Le Site peut proposer des liens vers des sites Internet de tiers susceptibles de Vous
                            intéresser.
                        </p>
                        <p>Tizy n’exerce aucun contrôle sur le contenu de sites de tiers ou sur les pratiques de ces
                            tiers en matière de protection des Données Personnelles qu’ils pourraient recueillir. En
                            conséquence, Tizy décline toute responsabilité concernant le traitement par ces tiers de Vos
                            Données Personnelles. Il est de votre responsabilité de Vous renseigner sur les politiques
                            de protection des données personnelles de ces tiers.
                        </p>
                        <h2>Article 8 - Transfert de données personnelles</h2>
                        <p>En cas d’accès au Site depuis un pays non membre de l’Union européenne dont la législation
                            relative à la collecte, à l’utilisation et au transfert de données diffère de celles de
                            l’Union européenne, nous attirons votre attention sur le fait qu’en continuant d’utiliser le
                            Site, qui sont régis par le droit français, les Conditions d’utilisation correspondantes et
                            la présente Politique, Vous transférez Vos Données Personnelles vers l’Union européenne et
                            consentez à ce transfert.
                        </p>
                        <p>Vos Données Personnelles peuvent être divulguées au sein de Tizy aux fins énoncées à
                            l’Article 3 de la présente Politique.
                        </p>
                        
                        <p>Tizy peut transférer Vos Données Personnelles en dehors de l’Union européenne, à condition de
                            s’assurer, avant de les transférer, que les entités extérieures à l’Union européenne,
                            offrent un niveau de protection adéquat, conformément à la législation européenne en
                            vigueur.
                        </p>
                        
                        <p>Si Tizy apprenait qu’un tiers auquel Tizy a communiqué des Données Personnelles aux fins
                            énoncées à l’Article 3 ci-dessus, utilise ou divulgue des Données Personnelles sans
                            respecter la présente Politique ou en violation de la législation applicable, Tizy prendrait
                            toute mesure raisonnable pour prévenir ou mettre fin à une telle utilisation ou divulgation.
                        </p>
                        <p>Vous disposez également du droit d’autoriser ou non Tizy à utiliser Vos Données Personnelles
                            pour une finalité autre que celles pour lesquelles ces Données Personnelles ont initialement
                            été collectées. Vous pouvez vous opposer à cet usage en vous référant à l'Article 8
                            ci-dessous.
                        </p>
                        <p>Tizy peut également être amené à transférer Vos Données Personnelles à des tiers si Tizy
                            estime qu’un tel transfert est nécessaire pour des raisons techniques (par exemple, pour des
                            services d’hébergement par un tiers) ou pour protéger ses intérêts légaux (par exemple, en
                            cas de vente d’actifs à une société tiers, de changement de contrôle ou de liquidation
                            totale ou partielle de Tizy).
                        </p>
                        <p>Tizy peut également partager vos Données Personnelles avec des partenaires commerciaux
                            lorsque Tizy et le partenaire commercial sponsorisent conjointement un évènement ou
                            participent ensemble à une promotion marketing dans laquelle Vous vous engagez.
                        </p>
                        <p>Tizy peut communiquer Vos Données Personnelles si la loi l’y oblige ou si Tizy estime de
                            toute bonne foi qu’une telle divulgation est raisonnablement nécessaire pour se conformer à
                            une procédure légale (par exemple, un mandat, une citation à comparaître ou toute autre
                            décision de justice) ou pour protéger les droits, les biens ou la sécurité personnelle de
                            Tizy, de nos clients ou du public.
                        </p>
                        <p>Si la législation applicable le permet, Tizy peut également partager Vos Données Personnelles
                            avec des tiers afin de Vous proposer des offres marketing ou publicitaires ciblées.
                        </p>
                        
                        <p>Ces transferts pourront avoir lieu par Internet ou par toute autre méthode jugée opportune
                            par Tizy conformément à la législation applicable et aux politiques internes de sécurité de
                            Tizy.
                        </p>
                        <h2>Article 9 - Vos droits</h2>
                        <h3>Votre droit d’accès et à la rectification de vos données
                        </h3>
                        <p>Vous disposez du droit d’accéder et de faire rectifier vos données personnelles.
                        </p>
                        <p>Vous pouvez nous demander que vos données personnelles soient, selon les cas, rectifiées,
                            complétées si elles sont inexactes, incomplètes, équivoques, périmées.
                        </p>
                        <h3>Votre droit à l’effacement de vos données
                        </h3>
                        <p>Vous pouvez nous demander l’effacement de vos données personnelles lorsque l’un des motifs
                            suivants s’applique :
                        </p>
                        <ul>
                            <li>les données personnelles ne sont plus nécessaires au regard des finalités pour
                                lesquelles elles ont été collectées ou traitées d’une autre manière ;
                            </li>
                            <li>vous retirez le consentement préalablement donné ;
                            </li>
                            <li>vous vous opposez au traitement de vos données personnelles pour motif légitime ;
                            </li>
                            <li>le traitement de données personnelles n’est pas conforme aux dispositions de la
                                législation et de la réglementation applicable.
                            </li>
                        </ul>
                        <p>Néanmoins, l’exercice de ce droit ne sera pas possible lorsque la conservation de vos données
                            personnelles est nécessaire au regard de la législation ou de la réglementation et notamment
                            par exemple pour la constatation, l’exercice ou la défense de droits en justice.
                        </p>
                        <h3>Votre droit à la limitation des traitements de données</h3>
                        <p>Vous pouvez demander la limitation du traitement de vos données personnelles dans les cas
                            prévus par la législation et la réglementation.</p>
                        <h3>Votre droit de vous opposer aux traitements de données</h3>
                        <p>Vous avez le droit de vous opposer à un traitement de données personnelles vous concernant
                            lorsque le traitement est fondé sur l’intérêt légitime du responsable du traitement.</p>
                        <h3>Votre droit à la portabilité de vos données</h3>
                        <p>Depuis le 25 mai 2018, vous disposez du droit à la portabilité de vos données
                            personnelles.</p>
                        <p>Les données sur lesquelles peut s’exercer ce droit sont :
                        </p>
                        <ul>
                            <li>uniquement vos données personnelles, ce qui exclut les données personnelles anonymisées
                                ou les données qui ne vous concernent pas ;
                            </li>
                            <li>les données personnelles déclaratives ainsi que les données personnelles de
                                fonctionnement évoquées précédemment ;
                            </li>
                            <li>les données personnelles qui ne portent pas atteinte aux droits et libertés de tiers
                                telles que celles protégées par le secret des affaires.
                            </li>
                        </ul>
                        
                        <p>Ce droit est limité aux traitements basés sur le consentement ou sur un contrat ainsi qu’aux
                            données personnelles que vous avez personnellement générées.
                        </p>
                        
                        <p>Ce droit n’inclut ni les données dérivées ni les données inférées, qui sont des données
                            personnelles créées par Tizy.
                        </p>
                        <h3>Votre droit de retirer votre consentement
                        </h3>
                        <p>Lorsque les traitements de données que nous mettons en œuvre sont fondés sur votre
                            consentement, vous pouvez le retirer à n’importe quel moment. Nous cessons alors de traiter
                            vos données à caractère personnel sans que les opérations antérieures pour lesquelles vous
                            aviez consenti ne soient remises en cause.
                        </p>
                        <h3>Votre droit d’introduire un recours</h3>
                        <p>Vous avez le droit d’introduire une réclamation auprès de la CNIL sur le territoire français
                            et ce sans préjudice de tout autre recours administratif ou juridictionnel.
                        </p>
                        <h3>Votre droit de définir des directives post-mortem</h3>
                        <p>Vous avez la possibilité de définir des directives relatives à la conservation, à
                            l’effacement et à la communication de vos données personnelles après votre décès et ce
                            auprès d’un tiers de confiance, certifié et chargé de faire respecter la volonté du défunt
                            conformément aux exigences du cadre juridique applicable.
                        </p>
                        <h3>Les modalités d’exercice de vos droits</h3>
                        <p>Tous les droits énumérés ci-avant peuvent être exercés :</p>
                        <ul>
                            <li>par courrier postal à l’adresse suivante : Tizy, 4 rue du Pensionnat Notre Dame de
                                France, 43000 LE PUY-EN-VELAY, en joignant une copie de votre carte d'identité et un
                                justificatif de domicile ;
                            </li>
                            <li>via notre adresse mail dpo@tizy.fr. Une procédure vous sera alors envoyée afin de
                                valider votre identité.
                            </li>
                        </ul>
                        <p>Note : les documents envoyés dans le cadre de cette procédure seront purement détruits, et
                            les données potentiellement recueillies durant la procédure seront définitivement
                            supprimées, à l'issue de cette dernière.
                        </p>
                        <p>Notez également que toutes les demandes ne respectant pas ces directives seront ignorées.</p>
                        <p>Nous nous engageons à répondre à ces demandes dans un délai d’1 mois à compter de la
                            réception de la demande. Ce délai peut être prolongé de 2 mois selon la complexité et le
                            nombre de demandes reçues, soit 3 mois au total.
                        </p>
                        <p>Si vous disposez d’un compte, vous pouvez exercer Vos droits d’accès et de rectification en
                            vous connectant à votre compte.
                        </p>
                        <p>Néanmoins, en ce qui concerne l’exercice du droit à l’information, nous pouvons ne pas avoir
                            l’obligation d’y donner suite si :
                        </p>
                        <ul>
                            <li>vous disposez déjà de cette information ;
                            </li>
                            <li>l’enregistrement ou la communication de vos données personnelles sont expressément
                                prévus par la loi ;
                            </li>
                            <li>la communication d’informations se révèle impossible ;
                            </li>
                            <li>la communication d’informations exigerait des efforts disproportionnés.
                            </li>
                        </ul>
                        <h2>Article 10 - Sécurité et destinataires des données
                        </h2>
                        <p>Tizy veille à protéger et sécuriser les Données Personnelles que Vous avez choisies de lui
                            communiquer, afin d’assurer leur confidentialité et empêcher qu’elles ne soient déformées,
                            endommagées, détruites ou divulguées à des tiers non autorisés.
                        </p>
                        <p>Tizy a pris des mesures de protection physiques, électroniques et organisationnelles pour
                            prévenir toute perte, mauvaise utilisation, accès ou diffusion non autorisé, altération ou
                            destruction éventuelle de ces Données Personnelles. Parmi ces mesures de protection, Tizy
                            intègre des technologies spécialement conçues pour protéger les Données Personnelles durant
                            leur
                            transfert. Toutefois, malgré les efforts de Tizy pour protéger Vos Données Personnelles,
                            Tizy ne
                            peut pas garantir l’infaillibilité de cette protection en raison de risques inévitables
                            pouvant
                            survenir lors de la transmission de Données Personnelles.
                        </p>
                        <p>Toutes les Données Personnelles étant confidentielles, leur accès est limité aux
                            collaborateurs et prestataires de Tizy qui en ont besoin dans le cadre de l’exécution de
                            leur
                            mission. Toutes les personnes ayant accès à Vos Données Personnelles sont tenues par une
                            obligation de confidentialité et s’exposent à des mesures disciplinaires et/ou autres
                            sanctions
                            si elles ne respectent pas ces obligations.
                        </p>
                        <p>Toutefois, il importe que Vous fassiez preuve de prudence pour empêcher tout accès non
                            autorisé à Vos Données Personnelles. Vous êtes responsable de la confidentialité de Votre
                            mot de
                            passe et des informations figurant dans Votre compte. Par conséquent, Vous devez Vous
                            assurer de
                            fermer Votre session en cas d’utilisation partagée d’un même ordinateur ou d’un même
                            terminal.
                        </p>
                        <h2>Article 11 - Résolution des conflits</h2>
                        <p>Bien que Tizy ait pris des mesures raisonnables pour protéger les Données Personnelles,
                            aucune technologie de transmission ou de stockage n’est totalement infaillible.
                        </p>
                        <p>Toutefois, Tizy est soucieuse de garantir la protection des Données Personnelles. Si Vous
                            avez des raisons de penser que la sécurité de Vos Données Personnelles a été compromise ou
                            qu’elles ont fait l’objet d’une utilisation abusive, Vous êtes invité à contacter Tizy à
                            l’adresse suivante : <a href="mailto:dpo@tizy.fr">dpo@tizy.fr</a>.</p>
                        <p>Tizy instruira les réclamations concernant l’utilisation et la divulgation de Données
                            Personnelles et tentera de les résoudre conformément aux principes figurant dans la présente
                            Politique.
                        </p>
                        <p>L’accès non autorisé à des Données Personnelles ou leur mauvaise utilisation peut constituer
                            une infraction aux termes de la législation locale.
                        </p>
                        <h2>Article 12 - Contact</h2>
                        <p>Pour toute question concernant la présente Politique, pour ne plus recevoir d’informations de
                            la part de Tizy ou pour toute demande de rectification, complément, mise à jour ou
                            suppression de Vos Données Personnelles, Vous pouvez envoyer un courrier électronique à
                            l’adresse électronique suivante : dpo@tizy.fr.
                        </p>
                        <h2>Article 13 - Date d’entrée en vigueur et révisions de la politique de protection des données
                            personnelles
                        </h2>
                        <p>La présente Politique peut être mise à jour en fonction des besoins de Tizy et des
                            circonstances ou si la loi l’exige. Nous vous invitons par conséquent à prendre
                            régulièrement connaissance des mises à jour.
                        </p>
                        
                        <p>Dernière modification : 03 avril 2023
                        </p>
                        <p>Version actuelle : 1.0
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}