import {DeeplinkEnum, UserAgentEnum} from '../../enumerator/deeplink.enum';

export const getAppUrl = (roomId: string, os: UserAgentEnum): string => {
    return  os === UserAgentEnum.IOS ? `${DeeplinkEnum.IOS_APP}?room=${roomId}` :
        `app://${DeeplinkEnum.ANDROID_APP}?room=${roomId}`;
}

export const getMobileOs = (): UserAgentEnum | null => {
    const os = getMobileOperation();
    if (os === UserAgentEnum.IOS || os === UserAgentEnum.ANDROID) {
        return os;
    }
    return null;
}

export const getMobileOperation = () => {
    let userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/android/i.test(userAgent)) {
        return UserAgentEnum.ANDROID;
    }

    if ((/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream)) {
        return UserAgentEnum.IOS;
    }

    return 'unknown';
}