import React, {useEffect, useState} from 'react';
import {ReactComponent as SoundOnImg} from '../../../assets/images/sound/music-on.svg';
import {ReactComponent as SoundOffImg} from '../../../assets/images/sound/music-off.svg';
import {ReactComponent as OctogoneImg} from '../../../assets/images/game/octogone.svg';
import {ReactComponent as WaitImg} from '../../../assets/images/online/wait/wait.svg';
import {ReactComponent as ExtraWaitImg} from '../../../assets/images/online/wait/wait-choice.svg';
import {ChoicesComponent} from '../../choices/choices.component';
import {ChoiceEnum} from '../../../enumerator/choice.enum';
import {getPicture, getScore} from '../../../services/game/game.service';
import {ResultComponent} from '../../result/result.component';
import {HeaderComponent} from '../../header/header.component';
import {CounterComponent} from '../../counter/counter.component';
import { Haptics } from '@capacitor/haptics';
import styles from './game.module.scss';
import {emitExtraSocket, emitRoomData, onExtraSocket, onRoomData,} from '../../../services/socket/socket.service';
import {LinkComponent} from '../../link/link.component';
import {checkResultByHistory, randomExtra} from '../../../services/extra/extra.service';
import {ExtraEnum, ExtraIdEnum, ExtraType} from '../../../types/extra.type';
import {defaultRoomData, RoomDataType} from '../../../types/socket.type';
import {ExtraChoiceComponent} from '../extra/choice/extraChoice.component';
import {ExtraInfoComponent} from '../extra/info/extraInfo.component';
import {useTranslation} from 'react-i18next';

const audioLoader = new Audio(require(`../../../assets/sound/online_${Math.floor(Math.random() * 2)}.mp3`))

export const OnlineGamePage = ({room, socketId}: { room: string, socketId: string }) => {
    const {t} = useTranslation();
    const [audio] = useState(audioLoader);
    const [loading, setLoading] = useState<boolean>(false);
    const [sound, setSound] = useState<boolean>(false);

    const [round, setRound] = useState<number>(1);

    const [result, setResult] = useState<number | undefined>(undefined);
    const [resultHistory, setResultHistory] = useState<number[]>([]);

    const [waitingExtra, setWaitingExtra] = useState<ExtraEnum | null>(null);

    const [meRoomData, setMeRoomData] = useState<RoomDataType>({
        ...defaultRoomData,
        id: socketId,
    });
    const [opponentRoomData, setOpponentRoomData] = useState<RoomDataType>(defaultRoomData);

    const [inGame, setInGame] = useState<boolean>(false);
    const [inExtra, setInExtra] = useState<boolean>(false);

    /**
     * On écoute les choix en arrivant dans la room
     */
    useEffect(() => {
        if (!!room && !!socketId) {
            setMeRoomData({
                ...defaultRoomData,
                id: socketId,
            });
            onRoomData(handleRoomData);
            onExtraSocket((extra: ExtraType) => handleExtra(extra))
        }
    }, [room]);

    useEffect(() => {
        if (!!meRoomData) {
            emitRoomData(meRoomData);
        }
    }, [meRoomData]);


    useEffect(() => {
        handleChoice();
        handleRound();
    }, [meRoomData, opponentRoomData]);

    /**
     * Quand les infos de la room se mettent à jour.
     * @param newRoomData: RoomDataType
     */
    const handleRoomData = (newRoomData: RoomDataType) => {
        if (newRoomData.id !== socketId) {
            setOpponentRoomData(newRoomData)
        }
    }

    /**
     * Si on a tous les choix sur le même round : on envoie le timer de loading
     */
    useEffect(() => {
        if (inGame) {
            setLoading(true);
        }
    }, [inGame]);

    /**
     * On envoie le choix de l'utilisateur.
     * @param choice: ChoiceEnum
     */
    const onChoice = (choice: ChoiceEnum) => {
        setMeRoomData({
            ...meRoomData,
            choices: [...meRoomData?.choices, choice]
        })
    }

    /**
     * On a un update de choix et on n'est pas en partie
     */
    const handleChoice = () => {
        if (!inGame) {
            const issetOpponent = opponentRoomData?.choices?.length > 0 &&
                (opponentRoomData?.choices?.length === 2 || opponentRoomData.extra !== ExtraIdEnum.AMBIDEXTRIE);
            const issetPlayer = meRoomData?.choices?.length > 0 &&
                (meRoomData?.choices?.length === 2 || meRoomData.extra !== ExtraIdEnum.AMBIDEXTRIE);
            setInGame(issetPlayer && issetOpponent);
        }
    }

    /**
     * On affiche les mains + calcul du résultat (call après timer).
     */
    const displayHands = () => {
        setLoading(false);
        const choicesMe = meRoomData?.choices;
        const choicesOpponent = opponentRoomData?.choices;
        if (!!meRoomData && choicesMe?.length && choicesOpponent?.length) {
            hapticsVibrate();
            setTimeout(() => {
                const score = getScore({
                    player1: choicesMe,
                    player2: choicesOpponent
                });
                if (score === 1) {
                    setMeRoomData({
                        ...meRoomData,
                        score: meRoomData.score + 1
                    })
                }
                setResult(score);
                setResultHistory([
                    ...resultHistory,
                    score
                ]);
            }, 2500);
        }
    }

    /**
     * Chargement du round suivant
     */
    const nextRound = () => {
        setMeRoomData({
            ...meRoomData,
            round: meRoomData.round + 1
        });
    }

    /**
     * Quand on détecte un changement de round entre le joueur et l'adversaire.
     */
    const handleRound = () => {
        const roundMe = meRoomData.round;
        const roundOpponent = opponentRoomData?.round;
        if (roundMe === roundOpponent && round !== roundMe) {
            setInGame(false);
            setMeRoomData({
                ...meRoomData,
                extra: null,
                choices: []
            });
            checkExtra();
            setRound(round + 1)
            setResult(undefined);
        }
    }

    /**
     * Lance ou coupe la musique
     */
    const toggleMusic = () => {
        !sound ? audio.play() : audio.pause();
        setSound(!sound);
    }

    /**
     * Check si on a un bonus / malus pour la prochaine manche
     * On vérifie si on n'a pas déjà reçu un malus de l'autre joueur
     */
    const checkExtra = () => {
        const bonus = checkResultByHistory(1, resultHistory);
        const malus = checkResultByHistory(2, resultHistory);
        setWaitingExtra(bonus ? ExtraEnum.BONUS : (malus ? ExtraEnum.MALUS : null));
    }

    /**
     * On prend le bonus ou envoie le malus
     * @param type: ExtraEnum
     */
    const onExtra = (type: ExtraEnum) => {
        const extra = randomExtra(type);
        emitExtraSocket(extra, opponentRoomData?.id as string);
        setWaitingExtra(null);
        setInExtra(true);
        if (type === ExtraEnum.BONUS) {
            setMeRoomData({
                ...meRoomData,
                extra: extra.id
            })
        }
    }

    /**
     * On reçoit les extras et on prend le malus s'il y a
     * @param extra: ExtraType
     */
    const handleExtra = (extra: ExtraType) => {
        setWaitingExtra(null);
        setInExtra(true);
        if (extra.type === ExtraEnum.MALUS) {
            setMeRoomData(prevState => {
                return {
                    ...prevState,
                    extra: extra.id
                };
            });
        }
    }

    /**
     * On fait vibrer le téléphone
     */
    const hapticsVibrate = () => {
        Haptics.vibrate().then(() => {});
    };

    let ChoiceOpponent;
    let ChoiceOpponentBonus;
    let ChoiceMe;
    let ChoiceMeBonus;

    if (opponentRoomData?.choices?.length) {
        ChoiceOpponent = getPicture(opponentRoomData?.choices[0] as ChoiceEnum, 'opponent');
        if (opponentRoomData?.choices?.length === 2) {
            ChoiceOpponentBonus = getPicture(opponentRoomData?.choices[1] as ChoiceEnum, 'opponent');
        }
    }
    if (meRoomData?.choices?.length) {
        ChoiceMe = getPicture(meRoomData?.choices[0] as ChoiceEnum, 'player');
        if (meRoomData?.choices?.length === 2) {
            ChoiceMeBonus = getPicture(meRoomData?.choices[1] as ChoiceEnum, 'player');
        }
    }

    // Si pas de résultat / pas de chargement / les 2 choix sont faits
    const handsDisplay = result === undefined && !loading && inGame;
    // On a un résultat / les 2 choix sont faits
    const resultDisplay = result !== undefined && inGame;
    // On a 1 seul choix et que c'est le nôtre ou on attend le second choix de l'adversaire
    const waitingOpponent = !handsDisplay &&
        (!!meRoomData?.choices?.length && (meRoomData?.extra !== ExtraIdEnum.AMBIDEXTRIE || meRoomData.choices.length === 2)) &&
        (!opponentRoomData?.choices?.length || (opponentRoomData?.extra === ExtraIdEnum.AMBIDEXTRIE && opponentRoomData?.choices?.length === 1));
    // Aucun résultat donc on peut faire un choix
    const displayChoice = result === undefined && waitingExtra !== ExtraEnum.MALUS;

    if (waitingExtra === ExtraEnum.BONUS) {
        return <ExtraChoiceComponent callback={(type: ExtraEnum) => onExtra(type)}/>
    }
    if (inExtra && (!!meRoomData?.extra || !!opponentRoomData?.extra)) {
        return <ExtraInfoComponent extra={(meRoomData?.extra || opponentRoomData?.extra) as string}
                                   callback={() => setInExtra(false)}
                                   ownExtra={!!meRoomData.extra}/>
    }
    return (
        <div className={`${styles.game} ${(handsDisplay) && styles.game_appear}`}>
            <HeaderComponent round={round}
                             online
                             player1={meRoomData.score}
                             player2={opponentRoomData?.score || 0}/>

            {handsDisplay && (
                <div className={styles.game__content}>
                    <div className={styles.choice}>
                        {ChoiceMe && (
                            <div className={`${styles.choice__hand} ${!!ChoiceMeBonus && styles.choice__hand_many}`}>
                                <ChoiceMe className={styles.choice__hand__img}/>
                                {ChoiceMeBonus && <ChoiceMeBonus className={styles.choice__hand__img}/>}
                            </div>
                        )}
                        {ChoiceOpponent && (
                            <div className={`${styles.choice__hand} ${!!ChoiceMeBonus && styles.choice__hand_many}`}>
                                <ChoiceOpponent className={styles.choice__hand__img}/>
                                {ChoiceOpponentBonus && <ChoiceOpponentBonus className={styles.choice__hand__img}/>}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {waitingOpponent && (
                <div className={styles.waiting}>
                    <WaitImg className={styles.waiting__img}/>
                    <p className={styles.waiting__title}>{t('gamePage.waiting1')} <br/> {t('gamePage.waiting2')}</p>
                </div>
            )}

            {waitingExtra === ExtraEnum.MALUS && (
                <div className={styles.waiting}>
                    <ExtraWaitImg className={styles.waiting__img}/>
                    <p className={styles.waiting__title}>{t('gamePage.fishy1')} <br/> {t('gamePage.fishy2')}</p>
                </div>
            )}

            {loading && <CounterComponent callback={displayHands}/>}

            {resultDisplay && (
                <ResultComponent result={result as number}
                                 online
                                 resetResult={() => nextRound()}
                                 waiting={meRoomData.round > round}
                                 choices={{
                                     player1: meRoomData?.choices as ChoiceEnum[],
                                     player2: opponentRoomData?.choices as ChoiceEnum[]
                                 }}/>
            )}

            {displayChoice && (
                <div
                    className={`${styles.game__footer} ${waitingExtra === ExtraEnum.MALUS && styles.game__footer_waiting}`}>
                    <div className='custom-container'>
                        <div className='row align-items-center'>
                            <div className='col-12 col-lg-6 offset-lg-3'>
                                <ChoicesComponent callback={(choice: ChoiceEnum) => onChoice(choice)}
                                                  extra={meRoomData?.extra || undefined}
                                                  choices={meRoomData?.choices as ChoiceEnum[]}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <OctogoneImg className={`${styles.game__background}`}/>

            <div className={`${styles.sound} d-none d-lg-flex`}>
                {sound ? <SoundOnImg className={styles.sound__img}/> : <SoundOffImg className={styles.sound__img}/>}
                <div className={styles.sound__content}>
                    <p className={styles.sound__text}>{t('gamePage.music')} {sound ? 'ON' : 'OFF'}</p>
                    <p className={styles.sound__copyright}>Crédit: Infraction</p>
                    <LinkComponent inheritSize
                                   callback={() => toggleMusic()}
                                   text={sound ? t('gamePage.switchOff') : t('gamePage.switchOn')}/>
                </div>
            </div>
        </div>
    )
}