import React, {useEffect, useState} from 'react';
import {LinkComponent} from '../../components/link/link.component';
import {Navigate} from 'react-router-dom';
import {ReactComponent as DisconnectImg} from '../../assets/images/online/disconnect.svg';
import styles from './disconnect.module.scss';
import {disconnectSocket} from '../../services/socket/socket.service';
import {useTranslation} from 'react-i18next';

export const DisconnectPage = () => {
    const {t} = useTranslation();
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

    useEffect(() => {
        disconnectSocket();
    }, [])

    if (!!redirectUrl) {
        return <Navigate to={redirectUrl}/>
    }
    return (
        <div className={styles.disconnect}>
            <div className={`custom-container ${styles.disconnect__content}`}>
                <div className='row'>
                    <div className='col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
                        <h1 className={styles.disconnect__title}>
                            {t('disconnectPage.text')}
                        </h1>
                    </div>
                </div>
            </div>
            <DisconnectImg className={styles.disconnect__img}/>
            <div className={styles.disconnect__link}>
                <LinkComponent text={t('disconnectPage.link')}
                               callback={() => setRedirectUrl('/mode')}
                               arrow/>
            </div>
        </div>
    );
}