import React, {useEffect, useState} from 'react';
import {ReactComponent as Challenger} from '../../../assets/images/online/challenger-here.svg';
import {ReactComponent as HandsHell} from '../../../assets/images/hand-hell.svg';
import {ReactComponent as ChallengerMobile} from '../../../assets/images/online/challenger-here-mobile.svg';
import {ReactComponent as HandsHellMobile} from '../../../assets/images/hand-hell-mobile.svg';

import styles from './waiting.module.scss';
import {useTranslation} from 'react-i18next';

const DURATION = 5;
export const WaitingPage = ({callbackTimer, gameOwner}: {
    callbackTimer: () => void
    gameOwner: boolean
}) => {
    const {t} = useTranslation();
    let timeoutFn: string | number | NodeJS.Timeout | undefined;
    const [timer, setTimer] = useState(DURATION);

    useEffect(() => {
        incrementTimer(DURATION);
        return () => {
            clearTimeout(timeoutFn)
        };
    }, []);

    const incrementTimer = (oldTime: number) => {
        timeoutFn = setTimeout(() => {
            setTimer(oldTime - 1);
            if (oldTime > 1) {
                incrementTimer(oldTime - 1);
                return
            }
            callbackTimer();
        }, 1000);
    }

    const text = !gameOwner ? t('waitingPage.otherTitle') : t('waitingPage.ownerTitle');

    return (
        <div className={styles.waiting}>
            <div className={`custom-container ${styles.waiting__content}`}>
                <div className='row'>
                    <div className='col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
                        <h1 className={styles.waiting__title}>{text}<br/>{t('waitingPage.launchIn')} <span
                            className={styles.waiting__timer}>{timer}</span></h1>
                        <p className={`${styles.waiting__text} d-none d-lg-block`}>{t('waitingPage.music')}</p>
                    </div>
                </div>
            </div>

            {gameOwner ? (
                <>
                    <ChallengerMobile className={`${styles.waiting__picture} d-md-none`}/>
                    <Challenger className={`${styles.waiting__picture} d-none d-md-block`}/>
                </>
            ) : (
                <>
                    <HandsHellMobile className={`${styles.waiting__picture} d-md-none`}/>
                    <HandsHell className={`${styles.waiting__picture} d-none d-md-block`}/>
                </>
            )}
        </div>
    );
}