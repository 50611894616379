import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {HomePage} from '../../pages/home/home.page';
import {PresentationPage} from '../../pages/presentation/presentation.page';
import {ModePage} from '../../pages/mode/mode.page';
import {GamePage} from '../../pages/game/game.page';
import {OnlinePage} from '../../pages/online/online.page';
import {DisconnectPage} from '../../pages/disconnect/disconnect.page';
import {CompletePage} from '../../pages/complete/complete.page';
import {LayoutComponent} from '../layout/layout.component';
import {RedirectPage} from '../../pages/redirect/redirect.page';
import {RgpdPage} from '../../pages/rgpd/rgpd.page';

export const RouterComponent = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<LayoutComponent/>}>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/rgpd" element={<RgpdPage />}/>
                    <Route path="/about" element={<PresentationPage/>}/>
                    <Route path="/mode" element={<ModePage/>}/>
                    <Route path="/game" element={<GamePage/>}/>
                    <Route path="/disconnect" element={<DisconnectPage/>}/>
                    <Route path="/complete" element={<CompletePage/>}/>
                    <Route path="/redirect" element={<RedirectPage/>}/>
                    <Route path="/:room"
                           element={<OnlinePage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}