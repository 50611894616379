import React, {useEffect, useState} from 'react';
import styles from './counter.module.scss';

export const CounterComponent = ({callback}: { callback: () => void }) => {
    let timeoutFn: string | number | NodeJS.Timeout | undefined;
    const [counter, setCounter] = useState<number>(3);

    useEffect(() => {
        incrementTimer(3);
        return () => {
            clearTimeout(timeoutFn);
        };
    }, []);

    const incrementTimer = (oldTime: number) => {
        timeoutFn = setTimeout(() => {
            setCounter(oldTime - 1);
            if (oldTime > 1) {
                incrementTimer(oldTime - 1);
                return
            }
        }, 900);
    }

    useEffect(() => {
        const callFunction = () => {
            if (counter === 0) {
                callback();
            }
        }
        callFunction()
    }, [callback, counter])

    return <p className={styles.timer} key={counter}>{counter}</p>
}