import {ExtraEnum, ExtraType} from '../../types/extra.type';
import {bonusData} from '../../data/bonus.data';
import {malusData} from '../../data/malus.data';

// Le nombre de victoires d'affilés pour un extra
export const ExtraNumber = 2;

/**
 * On vérifie si les 2 derniers gagnants sont similaires
 * @param player: number
 * @param resultHistory: number[]
 */
export const checkResultByHistory = (player: number, resultHistory: number[]): boolean => {
    const lastResultsVerification = resultHistory.slice(-(ExtraNumber + 1));
    const lastResults = resultHistory.slice(-ExtraNumber);
    if ((lastResultsVerification?.length === ExtraNumber + 1 && (lastResultsVerification[0] !== player)) ||
        lastResultsVerification?.length === ExtraNumber) {
        return lastResults?.length === ExtraNumber && lastResults.every(result => result === player)
    }
    return false;
}

/**
 * On vérifie si les 2 derniers gagnants sont similaires
 * @param type: ExtraEnum
 */
export const randomExtra = (type: ExtraEnum): ExtraType => {
    const data = (type === ExtraEnum.BONUS) ? bonusData : malusData;
    return data[Math.floor(Math.random() * 2)];
}