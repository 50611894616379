import {ReactComponent as Ambidextre} from '../assets/images/bonus/icons/ambidextre.svg';
import {ReactComponent as Puit} from '../assets/images/items/puit.svg';
import {ExtraEnum, ExtraIdEnum, ExtraType} from '../types/extra.type';

export const bonusData: ExtraType[] = [
    {
        id: ExtraIdEnum.AMBIDEXTRIE,
        img: Ambidextre,
        name: 'bonus.ambidextre.title',
        text: 'bonus.ambidextre.text',
        opponentText: 'bonus.ambidextre.opponentText',
        type: ExtraEnum.BONUS
    }, {
        id: ExtraIdEnum.PUIT,
        img: Puit,
        name: 'bonus.puit.title',
        text: 'bonus.puit.text',
        opponentText: 'bonus.puit.opponentText',
        type: ExtraEnum.BONUS
    }
]