import io from 'socket.io-client';
import {RoomDataType, StatusGameType} from '../../types/socket.type';
import {ExtraType} from '../../types/extra.type';

const wss = process.env.REACT_APP_SOCKET === 'dev' ? 'localhost:3000' :
    (process.env.REACT_APP_SOCKET === 'staging' ? 'wss://websocket.athena.tizy-studio.fr' : 'wss://websocket.tizy.fr')

const socket = io(wss, {
    autoConnect: false,
    withCredentials: true,
    extraHeaders: {
        'tizy-header': '39fq6FVNTycx76'
    }
});

export const cleanSocket = () => {
    socket.off('connect');
    socket.off('choice');
    socket.off('message');
    socket.off('reset');
}

export const disconnectSocket = () => {
    socket.close();
}

export const onConnectSocket = (room: string, callbackConnect: (id: string) => void) => {
    if (!socket.connected) {
        socket.connect();
    }
    socket.on('connect', () => {
        socket.emit('room', room);
        callbackConnect(socket.id);
    });
}

export const onResetSocket = (callback: () => void) => {
    socket.on('reset', () => {
        callback();
    });
}

export const onMessageSocket = (callback: (status: StatusGameType) => void) => {
    socket.on('message', (status: StatusGameType) => {
        callback(status);
    });
}

export const onRoomData = (callback: (roomData: RoomDataType) => void) => {
    socket.on('roomData', ({roomData}: { roomData: RoomDataType }) => {
        callback(roomData);
    });
}

export const onExtraSocket = (callback: (extra: ExtraType) => void) => {
    socket.on('extraData', ({extra}: { extra: ExtraType }) => {
        callback(extra);
    });
}

export const emitRoomData = (roomData: RoomDataType) => {
    socket.emit('roomData', {roomData});
}

export const emitExtraSocket = (extra: ExtraType, idOpponent: string) => {
    socket.emit('extraData', {
        extra,
        id: idOpponent
    });
}


