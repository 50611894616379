import styles from './result.module.scss';
import {ChoiceEnum} from '../../enumerator/choice.enum';

import {getResultInfo, getResultTextByMany} from '../../services/game/game.service';
import {LinkComponent} from '../link/link.component';
import {useTranslation} from 'react-i18next';

export const ResultComponent = ({result, choices, resetResult, online, waiting}: {
    result: number,
    choices: {
        player1: ChoiceEnum[],
        player2: ChoiceEnum[]
    },
    online?: boolean
    waiting?: boolean
    resetResult: () => void
}) => {
    const {t} = useTranslation();
    const {title, className, image} = getResultInfo(result, online);
    const text = getResultTextByMany(choices.player1, choices.player2, result, online)
    const Image = image;

    return (
        <div className={`${styles.result} ${online && styles.result_online} ${styles[className]}`}>
            {Image && <Image className={styles.result__img}/>}
            <p className={styles.result__title}>{t(title)}</p>
            <p className={styles.result__text}><span className={styles.result__text__content}>{t(text)}</span></p>
            <div className={styles.result__link}>
                {waiting ? (
                    <p className={styles.result__waiting}>{t('resultPage.waiting')}</p>
                ) : (
                    <LinkComponent text={t('resultPage.rerun')}
                                   arrow
                                   callback={resetResult}
                                   color={result === 2 ? 'var(--theme-background-dark)' : 'var(--theme-link)'}/>
                )}
            </div>
        </div>
    )
}