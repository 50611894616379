import React, {useState} from 'react';
import {ReactComponent as TizyImg} from '../../assets/images/tizy.svg';
import {ReactComponent as HomeImg} from '../../assets/images/home_pcc.svg';
import {LinkComponent} from '../../components/link/link.component';
import styles from './home.module.scss';
import {Navigate, useOutletContext} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ContextType} from '../../types/context.type';

export const HomePage = () => {
    const {isMobileApp} = useOutletContext<ContextType>();
    const {t} = useTranslation();
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

    if (!!redirectUrl) {
        if (!isMobileApp) {
            const audio = new Audio(require('../../assets/sound/okay.m4a'));
            audio.play();
        }
        return <Navigate to={redirectUrl}/>
    }
    return (
        <div className={styles.home}>
            <p className={styles.home__copyright}>
                {t('homePage.copyright')}
                <a href='https://www.tizy.fr' target='_blank'><TizyImg className={styles.home__copyright__img} /></a>
            </p>
            <HomeImg className={styles.home__picture}/>
            <div className={styles.home__link}>
                <LinkComponent text={t('homePage.link')}
                               callback={() => setRedirectUrl('/about')}
                               arrow/>
            </div>
        </div>
    )
}