import {ChoiceEnum} from '../enumerator/choice.enum';
import {ExtraType} from './extra.type';

export type StatusGameType = 'empty' | 'ready' | 'complete';

export type RoomDataType = {
    id: string
    round: number
    choices: ChoiceEnum[]
    extra: ExtraType['id'] | null
    score: number
}

export const defaultRoomData = {
    id: '',
    round: 1,
    score: 0,
    extra: null,
    choices: []
}