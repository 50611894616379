import React, {useState} from 'react';
import {ReactComponent as HandsHell} from '../../../assets/images/hand-hell.svg';
import {LinkComponent} from '../../link/link.component';
import {Share} from '@capacitor/share';
import {Clipboard} from '@capacitor/clipboard';
import {useTranslation} from 'react-i18next';

import styles from './code.module.scss';

export const CodePage = ({room, isMobileApp}: { room: string, isMobileApp: boolean }) => {
    const {t} = useTranslation();
    const [waiting, setWaiting] = useState<boolean>(false);
    const link = `pcc.tizy.fr/${room}`;

    const copyLink = async () => {
        if (isMobileApp) {
            await Clipboard.write({
                string: `https://${link}`
            });
            setWaiting(true);
        } else {
            try {
                if (navigator.clipboard) {
                    await navigator.clipboard.writeText(`https://${link}`);
                    setWaiting(true)
                }
            } catch (err) {
                console.error(`Failed to copy: ${err}`);
            }
        }
    }

    const shareLink = async () => {
        const data = {
            title: 'PCC | Tizy Game',
            text: 'Rejoins-moi pour jouer en ligne au PCC',
            url: `https://${link}`,
        };
        if (isMobileApp) {
            await Share.share(data);
        } else {
            try {
                if (navigator.share) {
                    await navigator.share(data);
                }
            } catch (err) {
                console.error(`Failed to share: ${err}`);
            }
        }
    }

    return (
        <div className={styles.code}>
            <div className={`custom-container ${styles.code__content}`}>
                <div className='row'>
                    <div className='col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
                        <h1 className={styles.code__title}>{t('copyPage.share')}</h1>
                        <div className={`${styles.code__copy} ${waiting && styles.waiting}`}
                             onClick={() => copyLink()}>
                            <p className={`${styles.code__copy__text} font-stacion`}>{t('copyPage.copy')}</p>
                            <div className={styles.code__copy__link}>
                                <LinkComponent text={link}
                                               color='var(--theme-background)'/>
                            </div>
                            <p className={styles.code__copy__waiting}>{t('copyPage.copySuccess')}</p>
                        </div>
                        <p className={styles.code__or}>{t('copyPage.or')}</p>
                        <div className={styles.code__share}
                             onClick={() => shareLink()}>
                            <span className={`material-symbols-outlined ${styles.code__share__icon}`}>ios_share</span>
                        </div>
                    </div>
                </div>
            </div>
            <HandsHell className={styles.code__picture}/>
        </div>
    );
}