
import {ChoiceEnum} from '../enumerator/choice.enum';


export const resultText: {
    [key: string]: {
        [key: string]: string
    }
} = {
    [ChoiceEnum.CISEAUX]: {
        [ChoiceEnum.PAPIER]: 'result.ciseaux.papier',
        [ChoiceEnum.CAILLOUX]: 'result.ciseaux.caillou',
        [ChoiceEnum.PUIT]: 'result.ciseaux.puit',
        [ChoiceEnum.PIED]: 'result.ciseaux.pied',
    },
    [ChoiceEnum.PAPIER]: {
        [ChoiceEnum.CISEAUX]: 'result.papier.ciseaux',
        [ChoiceEnum.CAILLOUX]: 'result.papier.caillou',
        [ChoiceEnum.PUIT]: 'result.papier.puit',
        [ChoiceEnum.PIED]: 'result.papier.pied',
    },
    [ChoiceEnum.CAILLOUX]: {
        [ChoiceEnum.CISEAUX]: 'result.caillou.ciseaux',
        [ChoiceEnum.PAPIER]: 'result.caillou.papier',
        [ChoiceEnum.PUIT]: 'result.caillou.puit',
        [ChoiceEnum.PIED]: 'result.caillou.pied',
    },
    [ChoiceEnum.PUIT]: {
        [ChoiceEnum.CISEAUX]: 'result.puit.ciseaux',
        [ChoiceEnum.CAILLOUX]: 'result.puit.caillou',
        [ChoiceEnum.PAPIER]: 'result.puit.papier',
    },
    [ChoiceEnum.PIED]: {
        [ChoiceEnum.CISEAUX]: 'result.pied.ciseaux',
        [ChoiceEnum.CAILLOUX]: 'result.pied.caillou',
        [ChoiceEnum.PAPIER]: 'result.pied.papier',
    },
}
