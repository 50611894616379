import React, {useEffect, useState} from 'react';
import {ReactComponent as JugFire} from '../../../../assets/images/bonus/bonus-jug.svg';
import styles from './extraInfo.module.scss';
import {LinkComponent} from '../../../link/link.component';
import {ExtraEnum, ExtraType} from '../../../../types/extra.type';
import {bonusData} from '../../../../data/bonus.data';
import {malusData} from '../../../../data/malus.data';
import {useTranslation} from 'react-i18next';

export const ExtraInfoComponent = (
    {
        callback,
        extra,
        ownExtra
    }: {
        extra: string,
        ownExtra: boolean,
        callback: () => void
    }
) => {
    const {t} = useTranslation();
    const [localExtra, setLocalExtra] = useState<ExtraType>()

    useEffect(() => {
        if (!!extra) {
            setLocalExtra(bonusData.find(item => item.id === extra) || malusData.find(item => item.id === extra))
        }
    }, [extra])

    if (!localExtra) {
        return <></>
    }
    const ImgExtra = localExtra?.img;
    const isBadNews = (ownExtra && localExtra.type === ExtraEnum.MALUS) || (localExtra.type === ExtraEnum.BONUS && !ownExtra);
    return (
        <div className={`${styles.extra} ${(isBadNews) && styles.extra_malus}`}>
            <div className='row'>
                <div className='col-12 col-lg-8 offset-lg-2'>
                    <JugFire className={styles.extra__img}/>
                    <p className={styles.extra__title}>
                        {ownExtra ? (
                            <>
                                {localExtra.type === ExtraEnum.BONUS ? t('extraPage.nextBonus') : t('extraPage.nextMalus')}
                            </>
                        ) : (
                            <>
                                {localExtra.type === ExtraEnum.BONUS ? t('extraPage.winBonus') : t('extraPage.loseMalus')}
                            </>
                        )}
                    </p>
                    <div className={styles.content}>
                        <div className={styles.content__picture}>
                            {!!ImgExtra && (
                                <ImgExtra className={styles.content__picture__img}/>
                            )}
                        </div>
                        <p className={styles.content__name}>{t(localExtra.name)}</p>
                        <p className={styles.content__text}>
                            {ownExtra ? t(localExtra.text) : t(localExtra.opponentText)}
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.extra__link}>
                <LinkComponent text={(isBadNews) ? t('extraPage.ohno') : t('extraPage.popopooow')}
                               arrow
                               callback={() => callback()}
                               color='#fff'/>
            </div>
        </div>
    );
}