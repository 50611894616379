import React, {useEffect, useState} from 'react';
import {ReactComponent as JugFire} from '../../assets/images/bonus/bonus-jug.svg';
import styles from './extra.module.scss';
import {LinkComponent} from '../link/link.component';
import {ExtraEnum, ExtraType} from '../../types/extra.type';
import {randomExtra} from '../../services/extra/extra.service';
import {useTranslation} from 'react-i18next';

export const ExtraComponent = ({callback, isBonus}: { isBonus: boolean, callback: (item: ExtraType) => void }) => {
    const {t} = useTranslation();
    const [extra, setExtra] = useState<ExtraType>()

    useEffect(() => {
        setExtra(randomExtra(isBonus ? ExtraEnum.BONUS : ExtraEnum.MALUS));
    }, [isBonus])

    if (!extra) {
        return <></>
    }
    const ImgExtra = extra.img;
    return (
        <div className={`${styles.extra} ${!isBonus && styles.extra_malus}`}>
            <div className='row'>
                <div className='col-12 col-lg-8 offset-lg-2'>
                    <JugFire className={styles.extra__img}/>
                    <p className={styles.extra__title}>
                        {isBonus ? (
                            <>{t('extra.bonus.text')}</>
                        ) : (
                            <>{t('extra.malus.text')}</>
                        )}
                    </p>
                    <div className={styles.content}>
                        <div className={styles.content__picture}>
                            <ImgExtra className={styles.content__picture__img}/>
                        </div>
                        <p className={styles.content__name}>{t(extra.name)}</p>
                        <p className={styles.content__text}>
                            {t(extra.text)}
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.extra__link}>
                <LinkComponent text={isBonus ? t('extra.bonus.link') : t('extra.malus.link')}
                               arrow
                               callback={() => callback(extra as ExtraType)}
                               color='#fff'/>
            </div>
        </div>
    );
}