import React from 'react';
import {useTranslation} from 'react-i18next';
import {ChoiceEnum} from '../../enumerator/choice.enum';
import {ReactComponent as CaillouImg} from '../../assets/images/items/caillou.svg';
import {ReactComponent as CiseauxImg} from '../../assets/images/items/ciseaux.svg';
import {ReactComponent as PapierImg} from '../../assets/images/items/papier.svg';
import {ReactComponent as PuitImg} from '../../assets/images/items/puit.svg';
import {ReactComponent as PiedImg} from '../../assets/images/items/pied.svg';
import {ExtraIdEnum} from '../../types/extra.type';
import styles from './choices.module.scss';

export const ChoicesComponent = ({choices, callback, extra}: {
    choices: ChoiceEnum[],
    callback: (choice: ChoiceEnum) => void,
    extra: ExtraIdEnum | undefined
}) => {
    const {t} = useTranslation();
    let disableChoice = (choices.length) && ((extra !== ExtraIdEnum.AMBIDEXTRIE) || (choices.length === 2));

    let choicesClass = disableChoice ? styles.choices_disabled : '';
    choicesClass += ' ' + (extra === ExtraIdEnum.BAPTISTE ? styles.choices_blured : '');
    return (
        <>
            <p className={`${styles.choices__text} ${disableChoice && styles.choices__text_none} ${(extra === ExtraIdEnum.BAPTISTE ? styles.choices__text_blured : '')}`}>
                {extra === ExtraIdEnum.AMBIDEXTRIE ? t('gamePage.choice2') : t('gamePage.choice')}
            </p>
            <div className={`${styles.choices} ${choicesClass}`}>
                {extra === ExtraIdEnum.PIED ? (
                    <>
                        <div
                            className={`${styles.choices__item} ${choices.includes(ChoiceEnum.PIED) ? styles.choices__item_active : ''}`}
                            onClick={() => callback(ChoiceEnum.PIED)}>
                            <PiedImg className={styles.choices__img}/>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className={`${styles.choices__item} ${choices.includes(ChoiceEnum.CAILLOUX) ? styles.choices__item_active : ''}`}
                            onClick={() => callback(ChoiceEnum.CAILLOUX)}>
                            <CaillouImg className={styles.choices__img}/>
                        </div>
                        <div
                            className={`${styles.choices__item} ${choices.includes(ChoiceEnum.CISEAUX) ? styles.choices__item_active : ''}`}
                            onClick={() => callback(ChoiceEnum.CISEAUX)}>
                            <CiseauxImg className={styles.choices__img}/>
                        </div>
                        <div
                            className={`${styles.choices__item} ${choices.includes(ChoiceEnum.PAPIER) ? styles.choices__item_active : ''}`}
                            onClick={() => callback(ChoiceEnum.PAPIER)}>
                            <PapierImg className={styles.choices__img}/>
                        </div>
                        {extra === ExtraIdEnum.PUIT && (
                            <div
                                className={`${styles.choices__item} ${choices.includes(ChoiceEnum.PUIT) ? styles.choices__item_active : ''}`}
                                onClick={() => callback(ChoiceEnum.PUIT)}>
                                <PuitImg className={styles.choices__img}/>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}