import {ReactComponent as CiseauxPlayer} from '../assets/images/hands/player/ciseaux.svg';
import {ReactComponent as PapierPlayer} from '../assets/images/hands/player/papier.svg';
import {ReactComponent as CaillouPlayer} from '../assets/images/hands/player/caillou.svg';
import {ReactComponent as PuitPlayer} from '../assets/images/hands/player/puit.svg';
import {ReactComponent as PiedPlayer} from '../assets/images/hands/player/pied.svg';
import {ReactComponent as CiseauxOpponent} from '../assets/images/hands/opponent/ciseaux.svg';
import {ReactComponent as PapierOpponent} from '../assets/images/hands/opponent/papier.svg';
import {ReactComponent as CaillouOpponent} from '../assets/images/hands/opponent/caillou.svg';
import {ReactComponent as PuitOpponent} from '../assets/images/hands/opponent/puit.svg';
import {ReactComponent as PiedOpponent} from '../assets/images/hands/opponent/pied.svg';
import {ChoiceEnum} from '../enumerator/choice.enum';


export const choicesPlayer = {
    [ChoiceEnum.CISEAUX]: {
        id: 0,
        picture: CiseauxPlayer
    },
    [ChoiceEnum.PAPIER]: {
        id: 1,
        picture: PapierPlayer
    },
    [ChoiceEnum.CAILLOUX]: {
        id: 2,
        picture: CaillouPlayer
    },
    [ChoiceEnum.PUIT]: {
        id: 3,
        picture: PuitPlayer
    },
    [ChoiceEnum.PIED]: {
        id: 4,
        picture: PiedPlayer
    },
}

export const choicesOpponent = {
    [ChoiceEnum.CISEAUX]: {
        id: 0,
        picture: CiseauxOpponent
    },
    [ChoiceEnum.PAPIER]: {
        id: 1,
        picture: PapierOpponent
    },
    [ChoiceEnum.CAILLOUX]: {
        id: 2,
        picture: CaillouOpponent
    },
    [ChoiceEnum.PUIT]: {
        id: 3,
        picture: PuitOpponent
    },
    [ChoiceEnum.PIED]: {
        id: 4,
        picture: PiedOpponent
    },
}