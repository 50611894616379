import React, {useState} from 'react';
import {ReactComponent as JugImg} from '../../assets/images/jug-feu.svg';
import {LinkComponent} from '../../components/link/link.component';
import {Navigate} from 'react-router-dom';
import styles from './presentation.module.scss';
import {useTranslation} from 'react-i18next';

export const PresentationPage = () => {
    const {t} = useTranslation();
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
    if (!!redirectUrl) {
        return <Navigate to={redirectUrl}/>
    }

    return (
        <div className='custom-container'>
            <div className='row'>
                <div className='col-12 col-lg-6 offset-lg-3'>
                    <div className={styles.presentation}>
                        <JugImg className={styles.presentation__picture}/>
                        <h1 className={styles.presentation__title}>{t('presentationPage.text')}</h1>
                        <div className={styles.presentation__link}>
                            <LinkComponent text={t('presentationPage.link')}
                                           callback={() => setRedirectUrl('/mode')}
                                           arrow/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}