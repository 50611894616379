import {ChoiceEnum, IndexChoiceEnum} from '../../enumerator/choice.enum';
import {ReactComponent} from '*.svg';
import {ReactComponent as EgalImg} from '../../assets/images/result/egale.svg';
import {ReactComponent as WinImg} from '../../assets/images/result/gagne.svg';
import {ReactComponent as LoseImg} from '../../assets/images/result/perd.svg';
import {ReactComponent as OnlineEgalImg} from '../../assets/images/online/results/egale.svg';
import {ReactComponent as OnlineLoseImg} from '../../assets/images/online/results/perd.svg';
import {ReactComponent as OnlineWinImg} from '../../assets/images/online/results/gagne.svg';
import {choicesOpponent, choicesPlayer} from '../../data/choices.data';
import {resultText} from '../../data/result.data';

export const getScore = ({player1, player2}: { player1: ChoiceEnum[], player2: ChoiceEnum[] }): number => {
    if (player1?.length === 1 && player2.length === 1) {
        return compareChoice(player1[0], player2[0]);
    } else if (player1?.length === 2) {
        let round1 = compareChoice(player1[0], player2[0]);
        let round2 = compareChoice(player1[1], player2[0]);
        if (round1 === 1 || round2 === 1) {
            return 1
        } else {
            return 2
        }
    } else if (player2.length === 2) {
        const round1 = compareChoice(player1[0], player2[0]);
        const round2 = compareChoice(player1[0], player2[1]);
        if (round2 !== 2 && round1 !== 2) {
            return 1
        } else {
            return 2
        }
    }
    return 0;
}

const compareChoice = (player1: ChoiceEnum, player2: ChoiceEnum): number => {
    player1 = player1 === ChoiceEnum.PIED ? ChoiceEnum.PAPIER : player1;
    player2 = player2 === ChoiceEnum.PIED ? ChoiceEnum.PAPIER : player2;
    if (player1 === player2) {
        return 0
    } else if (
        (player1 === ChoiceEnum.PAPIER && player2 === ChoiceEnum.CAILLOUX) ||
        (player1 === ChoiceEnum.CISEAUX && player2 === ChoiceEnum.PAPIER) ||
        (player1 === ChoiceEnum.CAILLOUX && player2 === ChoiceEnum.CISEAUX) ||
        (player1 === ChoiceEnum.PUIT && player2 !== ChoiceEnum.PAPIER) ||
        (player1 === ChoiceEnum.PAPIER && player2 === ChoiceEnum.PUIT)
    ) {
        return 1
    }
    return 2
}

const getDateIntArr = (): number[] => Date.now().toString().split('').reverse().map(number => parseInt(number))

export const makeRandomChoice = (): ChoiceEnum => {
    const dateIntArr = getDateIntArr() || [];
    const random: number[] = dateIntArr.map((number) => {
        let newVal: number = number;
        if (number > 2) {
            newVal = newVal % 3;
        }
        return newVal;
    });
    random.push(random.shift() as number);
    const choiceNumber = random[0] || Math.floor(Math.random() * 3);

    const indexChoice = IndexChoiceEnum[choiceNumber] as ChoiceEnum;
    return ChoiceEnum[indexChoice];
}

export const getPicture = (choice: ChoiceEnum, player: 'player' | 'opponent' = 'player'): typeof ReactComponent | undefined => {
    if (player === 'player') {
        return choicesPlayer[choice].picture;
    } else if (player === 'opponent') {
        return choicesOpponent[choice].picture;
    }
}

export const getResultInfo = (result: number, online?: boolean): {
    className: string,
    title: string,
    image: typeof ReactComponent | undefined
} => {
    switch (result) {
        case 1:
            return {
                className: 'result_win',
                title: 'resultPage.win',
                image: online ? OnlineWinImg : WinImg
            }
        case 2:
            return {
                className: 'result_lose',
                title: 'resultPage.lose',
                image: online ? OnlineLoseImg : LoseImg
            }
        default:
            return {
                className: 'result_egal',
                title: 'resultPage.egal',
                image: online ? OnlineEgalImg : EgalImg
            }
    }
}

export const getResultText = (choice1: ChoiceEnum, choice2: ChoiceEnum, online?: boolean) => {
    choice1 = choice1 === ChoiceEnum.PIED ? ChoiceEnum.PAPIER : choice1;
    choice2 = choice2 === ChoiceEnum.PIED ? ChoiceEnum.PAPIER : choice2;
    if (choice1 === choice2) {
        return online ? 'resultPage.egalOnline' : 'resultPage.egalOffline'
    }
    return resultText[choice1][choice2];
}


export const getResultTextByMany = (player1: ChoiceEnum[], player2: ChoiceEnum[], result: number, online?: boolean): string => {
    if (result === 0) {
        return getResultText(player1[0], player2[0], online)
    }
    if (player1.length === 2) {
        let round1 = compareChoice(player1[0], player2[0]);
        return round1 === result ? resultText[player1[0]][player2[0]] : resultText[player1[1]][player2[0]];
    }
    let round1 = compareChoice(player1[0], player2[0]);
    return round1 === result ? resultText[player1[0]][player2[0]] : resultText[player1[0]][player2[1]];
}