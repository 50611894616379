import React from 'react';
import styles from './header.module.scss';
import {ReactComponent as MeImg} from '../../assets/images/online/player/player-1.svg';
import {ReactComponent as ChallengerImg} from '../../assets/images/online/player/player-2.svg';
import {ReactComponent as PlayerImg} from '../../assets/images/game/player.svg';
import {ReactComponent as ComputerImg} from '../../assets/images/game/computer.svg';
import {ReactComponent as FireImg} from '../../assets/images/game/flamme.svg';
import {useTranslation} from 'react-i18next';

export const HeaderComponent = (
    {
        round,
        player1,
        player2,
        online
    }: { round: number, player1: number, player2: number, online?: boolean }) => {
    const {t} = useTranslation();
    return (
        <div className='custom-container'>
            <div className='row align-items-center'>
                <div className='col-12 col-lg-6 order-lg-1 mb-3 mb-lg-auto'>
                    <div className={styles.round}>
                        <FireImg className={styles.round__picture}/>
                        <p className={`${styles.round__number} ${online && styles.round__number_online} font-stacion`}>
                            {t('gamePage.round')} {round}
                        </p>
                    </div>
                </div>
                <div className='col-6 col-lg-3 order-lg-0'>
                    <div className={styles.player}>
                        {online ? <MeImg className={styles.player__img_online}/> :
                            <PlayerImg className={styles.player__img}/>}
                        <p className={styles.player__score}>{player1}</p>
                    </div>
                </div>
                <div className='col-6 col-lg-3 order-lg-2'>
                    <div className={styles.player}>
                        <p className={styles.player__score}>{player2}</p>
                        {online ? <ChallengerImg className={styles.player__img_online}/> :
                            <ComputerImg className={styles.player__img}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}